import React from 'react'
import PropTypes from 'prop-types'
import '../assets/css/team-element.css'

const GridElement = ({ title, subTitle, text, children }) => {

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 mx-auto max-w-screen-md py-24">
        
      <div className="col-span-1 self-center pb-16">
        <h1 className="h2 text-4xl text-black">{title}</h1>
        <p className="mb-8 md:pl-0 pb-4 md:pb-0 md:text-lg">{subTitle}</p>
        <p className="mb-5 md:pl-0 pb-4 md:pb-0 md:text-lg">{text}</p>
      </div>

      <div className="relative col-span-1">
      <div className="hidden md:block absolute border-b-3 border-secondary w-48 h-18 z-20 bottom-0 mb-20" />
        {children}
      </div>

    </div>
  )
}

GridElement.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  text: PropTypes.string,
}


export default GridElement