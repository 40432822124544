import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
// components
import GridElement from "../../components/teamElement"
import TopElement from "../../components/topElement"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


const CraftServicesPage = ({ data }) => {
  const image1 = getImage(data.team1Img.childImageSharp.gatsbyImageData)
  const image2 = getImage(data.team2Img.childImageSharp.gatsbyImageData)
  const image3 = getImage(data.team3Img.childImageSharp.gatsbyImageData)
  return (
    <Layout initialBackgroundColor="bg-white"
      headerTitle='Zeit und Raum'
      headerSub='Über Uns'
      bgImg='ueberUnsImmo'
      pageNav='immo'>
      <Seo title="Über Uns" />

      <div className="max-w-screen-lg mx-auto">

      <TopElement title='Qualität und Vielfalt' text='Zwei Dinge bestimmen unser Leben wesentlich: Zeit und Raum. Die Frage ist, wie wir mit der Zeit umgehen, und wie wir unseren Lebensraum gestalten. Die Firma Hossmann sorgt seit 1964 für hochwertigen Lebensraum. Unser Ziel ist stets: Räume zu schaf- fen, in denen man gerne Zeit ver- bringt. Das ist unser Anspruch – und das ist unser Versprechen an Sie.'/>

        <div className='team-grid'>
          <GridElement title='Adriano Hossmann' subTitle='Inhaber & Geschäftsführer' text='Adriano Hossmann absolvierte von 1972 bis 1975 die Gipserlehre. Nach einigen Lehr- und Wanderjahren erlangte er im Jahre 1983 die Meisterprüfung und stieg in die Geschäftsführung des elterlichen Betriebs ein. 10 Jahre führten Vater und Sohn die Firma gemeinsam. 1993 übergab Viktor Hossmann den Betrieb an seine Nachfolger.'>
              <GatsbyImage
                image={image1}
                className="bild"
                alt=""
              />
          </GridElement>
          <GridElement title='Gianluca Hossmann' subTitle='Immobilien Graubünden und Zürich' text='Gianluca Hossmann absolvierte von 2006 bis 2010 das Sportgymnasium an der Minerva Zürich. Nach fünfjähriger Karriere im Profisport wechselte er im Jahre 2015 in die Berufswelt und arbeitete unter anderem in den Bereichen Finanz- und Immobilienbuchhaltung, Immobilienverwaltung und -bewirtschaftung, sowie der Immobilienentwicklung. Parallel zu seiner beruflichen Tätigkeit, erlangte er seinen Abschluss in Betriebsökonomie an der Hochschule für Wirtschaft in Zürich.
Seit 2018 ist er in weiten Teilen für den strategischen Part, sowie Akquisition und Planung neuer Projekte der Hossmann Immobilien AG verantwortlich.'>
              <GatsbyImage
                image={image2}
                className="bild"
                alt=""
              />
          </GridElement>
          <GridElement title='Mauro Hossmann' subTitle='Gips und Trockenbau Zürich' text='Mauro Hossmann absolvierte von 2009 bis 2013 die Hotelfachschule in Passugg und schloss als Kaufmann mit eidg. Berufsmatura ab. Er entschied sich danach, eine Gipserlehre zu absolvieren und in den Familienbetrieb einzusteigen. Seit 2018 ist er für die Firma in Zürich verantwortlich und bildete sich berufsbegleitend zum Dipl. Techniker HF in Bauplanung/Architektur weiter.'>
              <GatsbyImage
                image={image3}
                className="bild"
                alt=""
              />
          </GridElement>
        </div>

      </div>
    </Layout>
  );
}

export default CraftServicesPage

export const pageQuery = graphql`{
  teamImage: file(relativePath: {eq: "company/team-gruppenbild.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  team3Img: file(relativePath: {eq: "company/team-mauro-hossmann.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  team1Img: file(relativePath: {eq: "company/team-adriano-hossmann.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  team2Img: file(relativePath: {eq: "company/team-gianluca-hossmann.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
  stuccoReferenceImage: file(
    relativePath: {eq: "references/valsana-hotel-arosa-entrance.jpg"}
  ) {
    childImageSharp {
      gatsbyImageData(quality: 80, layout: FULL_WIDTH)
    }
  }
}
`